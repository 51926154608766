import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Cell } from '../../global/grid';

import StyledStaffProfile from './StyledStaffProfile';
// import PDF from '../../../templates/pdf';

const StaffProfile = ({
  data,
  emailAddress,
  areas,
  // awards,
  memberships,
  pdfContent,
}) => (
  <StyledStaffProfile>
    <div className="StaffProfile__Card">
      <Grid>
        <Cell
          cellSize={{
            widescreen: 3,
            desktop: 3,
            tablet: 3,
            mobile: 12,
          }}
        >
          <aside className="StaffProfile-Sidebar">
            {areas && areas.length > 0 && (
              <>
                <h4 className="StaffProfile-Sidebar_Title">Specialist areas</h4>

                <ul className="StaffProfile-List">
                  {areas.map(area => (
                    <li
                      className="StaffProfile-List__item"
                      key={area.wordpress_id}
                    >
                      {area.title}
                    </li>
                  ))}
                </ul>
              </>
            )}
            {memberships && memberships.length > 0 && (
              <>
                <h4 className="StaffProfile-Sidebar_Title">Memberships</h4>
                <ul className="StaffProfile-List">
                  {memberships.map((m, k) => (
                    <li className="StaffProfile-List__item" key={k}>
                      {m.title}
                    </li>
                  ))}
                </ul>
              </>
            )}

            {emailAddress && (
              <div>
                <h4 className="StaffProfile-Sidebar_Title">Email address</h4>
                <p>
                  <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
                </p>
              </div>
            )}

            <div>
              <h4 className="StaffProfile-Sidebar_Title">Telephone number</h4>
              <p>
                <a href="tel:01325 341 500">01325 341 500</a>
              </p>
            </div>
            {/* <PDF
              pdfContext="staffProfile"
              data={{
                staff: data,
                areas,
                memberships,
                content: pdfContent || [],
              }}
            /> */}

            {/* {awards && awards.length > 0 && (
              <div className="StaffProfile-Accreditations">
                {awards.map((a, key) => (
                  <img
                    alt="Accreditation"
                    key={key}
                    src={a.featured_media.source_url}
                    className="StaffProfile-Accreditations__image"
                  />
                ))}
              </div>
            )} */}
          </aside>
        </Cell>

        <Cell
          cellSize={{
            widescreen: 8,
            desktop: 8,
            tablet: 8,
            phone: 11,
          }}
          cellOffset={{
            widescreen: 1,
            desktop: 1,
            tablet: 1,
            phone: 0,
          }}
        >
          <div className="StaffProfile__Body-Card">
            {data.profileBio && (
              <div dangerouslySetInnerHTML={{ __html: data.profileBio }} />
            )}
          </div>
        </Cell>
      </Grid>
    </div>
  </StyledStaffProfile>
);

StaffProfile.defaultProps = {
  emailAddress: null,
};

StaffProfile.propTypes = {
  data: PropTypes.shape({}).isRequired,
  emailAddress: PropTypes.string,
};

export default StaffProfile;
