import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Grid, Cell } from '../../components/global/grid';
import Backdrop from '../../components/atoms/backdrop/Backdrop';
import Hero from '../../components/common/hero/Hero';
import Lander from '../../images/static-landers/who-we-are-lander-blur.jpg';
import Layout from '../../components/layout';
import Container from '../../components/global/container/Container';
import StaticHeroContainer from '../../components/atoms/hero/StaticHeroContainer';
import StaffProfile from '../../components/common/staff-profile/StaffProfile';

const StyledImageWrap = styled.div`
  position: relative;
  height: 100%;

  @media print {
    height: 200px;
  }
`;

const StyledTitle = styled.h2`
  padding-top: 48px;

  @media print {
    padding-top: 24px;
    padding-bottom: 0;
  }
`;

const StyledForeword = styled.div`
  min-height: 340px;

  @media print {
    min-height: 100px;
    padding: 24px;
  }
`;

const StyledH1 = styled.h1`
  margin-bottom: 8px !important;
`;

const StyledH2 = styled.h2`
  margin-top: 0;
  margin-bottom: 16px;
`;

const StaffPage = ({ data, path }) => {
  const { title, staffAcf, acfStaffMeta, acfStaffPdf, seo } = data;

  return (
    <Layout seo={seo} path={path}>
      <Hero
        isLandingPage
        isStaffPage
        backgroundImageUrl={Lander}
        category="generic"
      >
        <Container isFullWidth>
          <StaticHeroContainer category="generic" noSideSpacing hideBlock>
            <Grid>
              <Cell>
                <div className="StaticHeroContainer__Title">
                  <StyledTitle>
                    <Link to={`/who-we-are/our-people`}>Our people</Link>
                  </StyledTitle>
                </div>
              </Cell>
            </Grid>

            <Grid alignItems="flex-end">
              <Cell
                cellSize={{
                  widescreen: 3,
                  desktop: 3,
                  tablet: 3,
                  mobile: 12,
                }}
              >
                <StyledImageWrap>
                  <img
                    src={staffAcf?.profileImage?.sourceUrl}
                    alt={title}
                    className="IndividualArticle__Image"
                    style={{ maxWidth: '100%' }}
                  />
                </StyledImageWrap>
              </Cell>
              <Cell
                cellSize={{
                  widescreen: 8,
                  desktop: 8,
                  tablet: 8,
                }}
                cellOffset={{
                  widescreen: 1,
                  desktop: 1,
                  tablet: 1,
                }}
              >
                <StyledForeword className="StaticHeroContainer__Foreword StaticHeroContainer__Foreword--Generic">
                  <StyledH1 className="StaticHeroContainer__Title">
                    {title}
                  </StyledH1>
                  <StyledH2 className="StaffProfile-Job_Title">
                    {staffAcf.jobTitle}
                  </StyledH2>

                  {staffAcf.profileForeword && (
                    <div className="StaticHeroContainer__ForewordCopy">
                      <p style={{ marginBottom: 24 }}>
                        {staffAcf.profileForeword}
                      </p>
                    </div>
                  )}
                </StyledForeword>
              </Cell>
            </Grid>
          </StaticHeroContainer>
        </Container>
      </Hero>

      <Backdrop>
        <Container>
          <Grid>
            <Cell>
              <StaffProfile
                data={staffAcf}
                emailAddress={staffAcf.emailAddress}
                memberships={acfStaffMeta.accreditations || []}
                areas={acfStaffMeta.areas}
                // pdfContent={acfStaffPdf}
                // awards={visibleAwards}
              />
            </Cell>
          </Grid>
        </Container>
      </Backdrop>
    </Layout>
  );
};

export default StaffPage;
