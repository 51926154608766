import styled from 'styled-components';

export default styled.div`
  padding-bottom: 48px;

  hr {
      margin-bottom: 24px;
    }

  .StaffProfile-Title {
    margin-bottom: 16px;
  }

  .StaffProfile-Job_Title {
    margin-bottom: 48px;
    font-size: 24px;
    color: #ffffff;

    @media screen and (min-width: 768px) {
      margin-bottom: 48px;
    }
  }

  .StaffProfile-Sidebar {
    margin-top: 48px;
    position: relative;

    @media screen and (min-width: 768px) {
      margin-top: 24px;
    }

    @media print {
      margin-top: 24px;
    }
  }

  .StaffProfile-Sidebar_Title {
    margin-bottom: 4px;
    font-weight: 600;
    color: rgba(0, 37, 74, 1);

    @media print {
      font-size: .85rem;
    }
  }

  .StaffProfile-Accreditations {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @media screen and (min-width: 1024px) {
      justify-content: flex-start;
    }
  }

  .StaffProfile-Accreditations__image {
    margin: 0 16px 24px 0;


    /* Workaround for images */
    @media screen and (min-width: 768px) and (max-width: 960px) {
      width: 70px;
      height: 70px;
    }

    @media screen and (min-width: 768px) {
      margin: 0 16px 8px 0;
    }
  }

  .StaffProfile-List {
    margin: 0 0 24px 0;
    padding: 0;
    list-style: none;

    @media screen and (min-width: 768px) {
      margin: 0 0 16px 0;

      &:last-child {
        margin: 0 0 32px 0;
      }
    }

    @media print {
      li {
        font-size: .75rem;
      }
    }
  }

  .StaffProfile-List__item {
    display: inline-block;
    font-family: 'henderson-sans-basic', sans-serif;
    color: rgba(0, 37, 74, 1);
    word-break: break-word;

    @media print {
      font-size: 16px;
    }

    &:after {
      content: '/';
      color: #f58021;
      padding: 0 4px;
    }

    &:last-child {
      &:after {
        content: '';
      }
    }
  }

  .StaffProfile__Image {
    position: relative;
    width: 100%;
    height: auto;
    margin-bottom: 32px;

    @media screen and (min-width: 768px) {
      margin-bottom: 80px;
    }
  }

  .StaffProfile__Body-Card {
    background-color: rgba(255,255,255,0.9);
    font-size: 18px;
    padding: 24px;

    ul, li, p {
      font-size: 18px;

      @media print {
        font-size: 16px;
      }
    }

    ul {
      padding-left: 20px;
    }

    ul li {
      margin-bottom: 1rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    @media screen and (min-width: 768px) {
      font-size: 22px;
      padding: 24px 42px;
    }

    @media screen and (min-width: 1024px) {
      padding: 42px 72px;
    }

    @media print {
      padding: 0;
      padding-top: 24px;

      p:last-of-type {
        margin-bottom: 0;
      }
    }

   .StaffProfile__Body-Card-Publications {
     margin-bottom: 24px;
   }

   .StaffProfile__Body-Card-Memberships {
      ul {
        padding-left: 24px;
      }
   }

    .StaffProfile__Return-Link {
      font-size: 20px;
      font-weight: 400;
      color: #f58021;
      font-family: henderson-sans-basic;
      @media screen and (min-width: 476px) {
        font-size: 26px;
      }
    }
`;
